<template>

  <b-card-code
    title="Change Password"
  >
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeStudentProfile)">
    
      <b-row class="mt-2">
        
        <b-col md="6">
        <b-form-group
                label-for="register-password"
                label="Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
              <!-- confirm password -->
              <b-col md="6">
              <b-form-group
                label-for="register-confirm-password"
                label="Confirm Password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:confirmation"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.confirm_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                      
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
        <!-- submit and reset -->
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            
          >
            Change Password
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver> 
    </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker,BInputGroup, BInputGroupAppend, BCol, BOverlay, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AuthService from '@/services/auth.service.js';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
        password: '',
        confirm_password: '',
      },
      logoImg: require('@/assets/images/pages/anclademia-favi.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
      showOverlay: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    
  },
  methods: {
    
    storeStudentProfile() {
        this.showOverlay = true;
          AuthService.changePassword(this.user).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password Updated Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                
            }).catch(error => {
              this.showOverlay = false;
                console.log(error)
            });
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
